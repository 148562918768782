import Resource from '@/api/resource';
import request from '@/utils/request';

class PostResource extends Resource {
  constructor() {
    super('admin/posts');
  }

  list(resource) {
    return request({
      url: '/' + this.uri,
      method: 'get',
    });
  }

  store(resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  delete(id){
    return request({
      url: '/admin/delete/posts',
      data: {id: id},
      method: 'post',
    });
  }

  update(slug, resource) {
    return request({
      url: '/' + this.uri + '/' + slug,
      method: 'post',
      data: resource,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  imgStore(resource) {
    return request({
      url: '/' + this.uri + '/store-image',
      method: 'post',
      data: resource,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export { PostResource as default };
